import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';

class Misc extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="Reading List" theme="gray" keywords={[`recommended reading`]} />
                <header>
                    <h1>Misc</h1>
                    <p>Things that don't fit anywhere else</p>
                </header>
                <article>
                    <header>
                        <h1>Music</h1>
                        <p>Songs I keep coming back to:</p>
                    </header>
                    <article>
                        <span>Happy:</span>
                        <ul className="music-list">
                            <li>Why Can't We Live Together by Timmy Thomas</li>
                            <li>Hello Stranger by Barbara Lewis</li>
                            <li>No Other Plans by Sunny Levine</li>
                            <li>Spooky by Dusty Springfield</li>
                        </ul>
                    </article>
                    <article>
                        <span>Moody:</span>
                        <ul className="music-list">
                            <li>Angst by Valentin Stip</li>
                            <li>Shadow Journal by Max Richter</li>
                            <li>DMT by xxyyxx</li>
                        </ul>
                    </article>
                    <article>
                        <span>Peaceful:</span>
                        <ul className="music-list">
                            <li>Poinciana by Ahmad Jamal</li>
                            <li>Koyal by Nitin Sawhney</li>
                            <li>Microphonist Wanderlust by Pete Rock & InI</li>
                            <li>Bourgeois by Co.fee</li>
                            <li>S Curve by The Seventh & Sango</li>
                            <li>On Air by JD. Reid</li>
                        </ul>
                    </article>
                    <article>
                        <span>Money:</span>
                        <ul className="music-list">
                            <li>New York Groove by Ace Frehley</li>
                        </ul>
                    </article>
                    <p>
                        Here's most of them in a{' '}
                        <a
                            href="https://open.spotify.com/playlist/7lr6r2ehyQc39mjwfVW9mi?si=fae416f1af0d4f72"
                            target="_blank"
                        >
                            Spotify playlist
                        </a>
                        .
                    </p>
                </article>
                <article>
                    <header>
                        <h1>Technology</h1>
                        <p>
                            This website is built with <a href="#">Gatsby</a> and hosted on{' '}
                            <a href="#">GitLab Pages</a>.
                        </p>
                    </header>
                    <p>
                        I'm a big believer in{' '}
                        <a href="https://pinboard.in/about/" target="_blank">
                            Maciej's philosophy
                        </a>
                        :
                    </p>
                    <blockquote>
                        There is absolutely nothing interesting about the Pinboard architecture or
                        implementation; I consider that a feature!
                    </blockquote>
                    <p>
                        Most of the photographs are shot with an Olympus OMD EM1 I bought in 2014.
                        The glass is an M.Zuiko 12-40mm f2.8 PRO.
                    </p>
                    <p>
                        But{' '}
                        <a href="https://www.kenrockwell.com/tech/notcamera.htm" target="_blank">
                            does the gear matter?
                        </a>
                    </p>
                </article>
                <article>
                    <header>
                        <h1>Fonts</h1>
                        <p>
                            The global font is{' '}
                            <a
                                href="https://www.abcdinamo.com/typefaces/favorit-pro"
                                target="_blank"
                            >
                                Favorit Pro by DINAMO
                            </a>
                            .<br />
                            The blog also uses{' '}
                            <a
                                href="https://fonts.google.com/specimen/Karla?selection.family=Karla"
                                target="_blank"
                            >
                                Karla
                            </a>
                            .<br />
                            For the love of god,
                            <br />
                            pay for your fonts.
                        </p>
                    </header>
                </article>
                <article>
                    <p>
                        <Link to="/">
                            <span className="arrow">←</span>&nbsp;&nbsp;Back to the rest of the
                            website.
                        </Link>
                    </p>
                </article>
            </Layout>
        );
    }
}

export default Misc;
